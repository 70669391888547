<template>
  <b-container>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Home</a></li>
        <li class="breadcrumb-item"><a href="#">Exemption</a></li>
        <li class="breadcrumb-item active" aria-current="page">
          Private Registration
        </li>
      </ol>
    </nav>
    <hr />
    <Private-Filter
      v-model="form"
      :boards="boards"
      :approvalStatus="approvalStatus"
      :events="events"
      @submit="onSubmit"
    ></Private-Filter
    >
    <hr />
    <b-row class="mb-2">
      <!-- <b-col md="3">
        <b-input-group>
          <b-form-input
            v-model="filter"
            placeholder="Type to Search"
            size="sm"
          />
          <b-input-group-append>
            <b-btn :disabled="!filter" @click="filter = ''" size="sm"
              >Clear</b-btn
            >
          </b-input-group-append>
        </b-input-group>
      </b-col> -->
      <b-col md="3">
        <p>Total Count: {{ totalRows }}</p>
      </b-col>
      <b-col>
        <b-button
          class="float-right mx-3"
          variant="outline-danger"
          size="sm"
          @click="convertToPdf"
          style="float: right; font-size: 1em; font-color: red"
        >
          <i class="fa fa-file-pdf"></i>
        </b-button>
        <b-button
          variant="outline-primary"
          size="sm"
          @click="downloadExcel"
          style="float: right; font-size: 1em; font-color: green"
        >
          <i class="fa fa-file-excel"></i>
        </b-button>
       
        <b-button
          v-if="
            showExtend &&
            (form.ApplicationStatus == 1 || form.ApplicationStatus == 2)
          "
          variant="warning"
          size="sm"
          @click="extendLoi"
          style="float: right; margin-right: 10px"
          >Payment Receipt</b-button
        >
      </b-col>
    </b-row>
 <loading :active.sync="isLoading" 
        color="#5dbbda"
        loader="spinner"
        :is-full-page="fullPage"
        width:2
        height:2
        ></loading>
    <b-table
      id="tblApplication"
      show-empty
      select-mode="multi"
      head-variant="dark"
      empty-text="No Applications available."
      stacked="md"
      bordered
      striped
      :items="applications"
      :fields="fields"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @filtered="onFiltered"
      :per-page="perPage"
      :current-page="currentPage"
      style="font-size: 12px"
    >
      <template v-slot:cell(Sr)="row">{{
        (currentPage - 1) * perPage + row.index + 1
      }}</template>
      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          @click.stop="viewProfile(row.item)"
          class="mr-1 pt-0 pb-0"
          variant="success"
        >
          <i class="fas fa-edit"></i>
        </b-button>
      </template>
      <template v-slot:cell(Gender)="row">
        <!-- <template slot="selected" slot-scope="row"> -->
        <template v-if="row.item.Gender">
          <span>Male</span>
        </template>
        <template v-else>
          <span>Female</span>
        </template>
      </template>
      <template v-slot:cell(ExServiceMan)="row">
        <!-- <template slot="selected" slot-scope="row"> -->
        <template v-if="row.item.ExServiceMan">
          <span>Yes</span>
        </template>
        <template v-else>
          <span></span>
        </template>
      </template>
      <template v-slot:cell(editDoc)="row">
        <b-button
          size="sm"
          @click.stop="editDocument(row.item)"
          class="mr-1 pt-0 pb-0"
          variant="warning"
          v-b-tooltip.hover
          title="Edit Doc"
          v-if="isAdmin"
          >Docs</b-button
        >
      </template>
      <template v-slot:cell(marks)="row">
        <b-button
          size="sm"
          @click.stop="enterMarks(row.item, row.index, $event.target)"
          class="mr-1 pt-0 pb-0"
          variant="warning"
          v-b-tooltip.hover
          title="Edit Doc"
          v-if="row.item.ApplicationStatusID == 6"
          >Marks</b-button
        >
      </template>
    </b-table>
    <b-row>
      <b-col md="8" class="my-1">
        <b-pagination
          :total-rows="totalRows"
          :per-page="perPage"
          v-model="currentPage"
          class="my-0"
          size="sm"
          @change="ChangeEvent"
        />
      </b-col>     
      <b-col md="2" class="my-1">
        <b-form-group
          horizontal
          label="Per Page"
          class="mb-0"
          label-size="sm"
          :label-cols="6"
        >
          <b-form-select
            :options="pageOptions"
            v-model="perPage"
            size="sm"
            @change="ChangeEventPage"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <div></div>
  </b-container>
</template>

<script>
import apis from "@/apis";
import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
import { mapState } from "vuex";
import lambda from "@/apis/lambda";
import * as _ from "lodash";
import axios from "axios";
import Spinner from "vue-simple-spinner";
import Formatter from "@/Formatter";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import XLSX from "xlsx";
import PrivateFilter from "@/components/PrivateFilter";

export default {
  props: ["userBoardID", "userApprovalStatus", "userPoolType"],
  components: { Spinner, PrivateFilter, Loading },
  data() {
    return {     
      showBtn: false,
      isLoading: false,
      fullpage: false,
      show: false,
      showsts: false,
      showExtend: false,
      modalInfo: "",
      boards: this.$store.getters.getBoards,
      events: this.$store.getters.getEvents,
      download_applications: [],
      pendingWithList: [
        "All",
        "DM",
        "DBSC",
        "AGM Project",
        "GM",
        "MD",
        "LOI",
        "Lapsed",
        "Disbursed",
        "Hold",
        "Rejected",
      ],
      //approvalStatus: this.$store.getters.getApprovalStatus,
      approvalStatus: [],
      applications: [],
      appCount: 0,
      appDate: null,
      fields: [
          {
          key: "sr_no",
          label: "Sr No",
          sortable: true,
        },
        {
          key: "RegistrationNumber",
          label: "Profile No",
          sortable: true,
        },
        {
          key: "RegistrationType",
          label: "Registration Type",
          sortable: true,
        },

        { key: "EntityName", label: "Entity Name", sortable: true },

        {
          key: "RecordDate",
          label: "Record Date",
          sortable: true,
        },
        {
          key: "RegisteringAgencny",
          label: "Registering Agencny",
          sortable: true,
        },
        {
          key: "AgencnyRegNo",
          label: "Agencny Reg No",
          sortable: true,
        },
        { key: "actions", label: "View" },
      ],

      form: {
        BoardID: "00000000-0000-0000-0000-000000000000",
        RegistrationType: 10,
        RegistrationNumber: "",
        EntityName: "",
        IsRegistered: true,
        Limit: 500,
      },

      currentPage: 1,
      perPage: 5,
      totalRows: 0,
      pageOptions: [10, 20, 50, 100, 200, 500],
      sortBy: null,
      sortDesc: false,
      filter: null,
      showDismissibleAlert: false,
      variant: "success",
      alertText: "",
      isLoading: false,
      isLoadingFile: false,
      isAdmin: false,
    };
  },
  computed: {   
    sortOptions() {
      // Create an options list from our fields
     //return this.items.length
       return this.fields.length
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    userBoard() {
      return this.$store.getters.getUserBoard;
    },
  },
  methods: {
    getdata() {
      this.isLoading = true;
      let promise = apis.SearchResult();
      promise
        .then((response) => {
          this.isLoading = false;
          this.approvalStatus = response.body;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    convertDateFormat(inDate) {
      return Formatter.convertDateFormat(inDate);
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    onReset2(evt) {
      evt.preventDefault();
    },

    deleteDocument(item, index, button) {
      //console.log(item);
      this.selecteObj.Document = item.Document;
      this.selecteObj.Path = item.Path;

      let info = "You want to delete record for:" + "\n" + item.Document;
      this.modalInfo = info;
      this.$root.$emit("bv::show::modal", "modalConfirm");
    },
    modalCancel() {
      this.selecteObj.Document = "";
      this.selecteObj.Path = "";
      this.$root.$emit("bv::hide::modal", "modalConfirm");
    },

    modalConfirm() {
      this.form.UserLoanDocuments[this.selecteObj.Document] = "";

      var userinfo = this.$store.getters.loggedUser;
      var LoginID = userinfo.UserId;
      //   var filename =   this.Photo.name;
      this.form.LoginID = LoginID;
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) return;

      this.isLoadingFile = true;

      var strings = files[0].name.split(".");
      if (strings.length > 0) {
        var extn = strings[strings.length - 1].toLowerCase();
      }

      if (extn != "jpeg" && extn != "png" && extn != "jpg" && extn != "pdf") {
        this.showDismissibleAlert = true;
        this.alertText = "Wrong File Input";
        this.variant = "warning";
        this.$refs.finp.reset();
        this.isLoadingFile = false;
        return;
      }

      let dt = Date.now();
      var filetype = files[0].type;
      var filepath = "bsc_" + dt + "." + extn;
      let promisefoto = lambda.getSignedURL("msobcfdc_bsc", filepath, filetype);

      promisefoto
        .then((response) => {
          let signedUrl = response;
          var options = {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": files[0].type,
            },
          };
          return axios.put(signedUrl, files[0], options);
        })
        .then((res) => {
          if (res.status == 200) {
            this.formBSC.ApprovalDoc = filepath;
            this.showBtn = true;
          } else {
            this.variant = "warning";
            this.alertText = "Error in uploading Image";
            this.showDismissibleAlert = true;
          }
          this.isLoadingFile = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoadingFile = false;
        });
    },
    viewProfile(item) {
        if (item.RegistrationTypeNum=="11") {
             this.$router.push({
        name: "EmployerProfile",
        params: {
         id:item.RegistrationID
        },
      });
            
        }
        if (item.RegistrationTypeNum=="12") {
             this.$router.push({
        name: "GuardProfile",
        params: {
         id:item.RegistrationID
        },
      });
            
        }
        if (item.RegistrationTypeNum=="13") {
             this.$router.push({
        name: "AgencyProfile",
        params: {
         id:item.RegistrationID
        },
      });
            
        }
     
    },
    ReadRegistrationDetails(item) {
      this.$router.push({
        name: "ReadRegistrationDetails",
          params: {
          BoardID: "00000000-0000-0000-0000-000000000000",
          RegistrationType: 10, //or 1
          Mobile: "",
          EntityName: "",
          IsRegistered: true, // or 0 for Female , 1 for Male
        },
      });
    },

    editDocument(item) {
      this.$router.push({
        name: "DocEdit",
        params: {
          UserID: item.UserID,
          ApplicationStatus: this.form.ApplicationStatus,
        },
      });
    },
    enterMarks(item, index, button, n) {
      this.$root.$emit("bv::show::modal", "mdlMarks");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    onSubmit(){
      if (this.appDate != null) {
        this.form.StartDate = Formatter.convertDateFormatYMD(this.appDate[0]);
        this.form.EndDate = Formatter.convertDateFormatYMD(this.appDate[1]);
      } else {
        this.form.StartDate = "0001-01-01";
        this.form.EndDate = "0001-01-01";
      }
      this.currentPage = 1;
      this.form.Limit = this.perPage;
       //this.form.Limit = 5;
      this.form.OffSet = 0;

      this.getApplicationsSub();
    },

    getApplications() {
      if (this.userBoard != "00000000-0000-0000-0000-000000000000") {
        this.form.BoardID = this.userBoard;
      }

      this.perPage = this.form.Limit;
      // this.perPage =500;
      this.currentPage = this.form.OffSet / this.perPage + 1;

      this.getApplicationsSub();
    },

    ChangeEvent(evt) {
      this.currentPage = evt;
      this.form.Limit = this.perPage;
      this.form.OffSet = (this.currentPage - 1) * this.perPage;
      this.getApplicationsSub();
    },

    ChangeEventPage(evt) {
      this.perPage = evt;
      this.currentPage = 1;
      this.form.Limit = this.perPage;
      this.form.OffSet = 0;
      this.getApplicationsSub();
    },

    getApplicationsSub() {
         console.log(this.form);
      this.isLoading = true;
      this.showDismissibleAlert = false;

    //   if (this.form.ApplicationStatus == 2) {
    //     this.showsts = true;
    //   } else {
    //     this.showsts = false;
    //   }

    //   if (this.form.EvaluationStatus == "0") {
    //     this.showbtn = true;
    //   } else {
    //     this.showbtn = false;
    //   }

      this.$store.dispatch("setSearchFormPvt", this.form);
     
      let data = {
        BoardID: this.form.BoardID,
        RegistrationType: this.form.RegistrationType, //or 1
        Mobile: this.form.Mobile,
        EntityName: this.form.EntityName,
        IsRegistered: this.form.IsRegistered,
        //Limit: this.form.Limit, // or 0 for Female , 1 for Male
      };
      this.isLoading=true;
      let promise = apis.SearchResult(data);

      promise
        .then((response) => {
          this.isLoading=false;
          console.log(response);
          //alert(response.status);
          if (response.status == 200) {
            this.applications = response.body;
            for (var i = 0; i < this.applications.length; i++) {
              this.applications[i].sr_no = i + 1;
            }
            //alert(this.applications.length);
            this.totalRows = this.applications.length;
            var userinfo = this.$store.getters.loggedUser;
          } else {
            this.alertText = response.body;

            this.variant = "warning";
            this.showDismissibleAlert = true;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.alertText = err.message;
          this.showDismissibleAlert = true;
          this.variant = "danger";
          this.isLoading = false;
        });

      //this.$refs.mdlAdvSearch.hide();
      //console.log(this.selectedDistrict);
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        BoardID: this.userBoard,
        EventID: "00000000-0000-0000-0000-000000000000",
        ApplicationStatus: 0,
        Gender: 3,
        MobileNo: "",
        ApplicationNo: "",
        StartDate: null,
        EndDate: null,
      };
    },

    convertToPdf() {
      var pdf = new jsPDF("l", "pt");
      var columns = [
          { dataKey: "sr_no", title: "Sr No" },
        { dataKey: "RegistrationNumber", title: "Profile No" },
        {
          dataKey: "RegistrationType",
          title: "Registration Type",
        },
        { dataKey: "EntityName", title: "Entity Name" },
        { dataKey: "RecordDate", title: "Record Date" },
        { dataKey: "RegisteringAgencny", title: "Registering Agencny" },
        { dataKey: "AgencnyRegNo", title: "Agencny RegNo" },
      ];

      var boardname = "";
      this.$store.getters.getBoards.forEach((item) => {
        if (
          item.SecurityBoardID == this.$store.getters.getAppSearchFormPvt.BoardID
        ) {
          boardname = item.SecurityBoardName;
        }
      });
      var ref = this;
      var pageContent = function (data) {
        pdf.text(boardname, pdf.internal.pageSize.getWidth() / 2, 25, "center");
        pdf.text(
          "Exemption List ",
          pdf.internal.pageSize.getWidth() / 2,
          50,
          "center"
        );
        pdf.setFontSize(12);
        pdf.text("Date :", 35, 70);
        pdf.text(ref.convertDateFormat(new Date()), 70, 70);
        pdf.page = 1;
        function footer() {
          pdf.text(
            "page " + pdf.page,
            pdf.internal.pageSize.getWidth() / 2,
            pdf.internal.pageSize.height - 10
          );
          pdf.page++;
        }
      };

      var start_y_pos = 80;
      var vc = this;
      pdf.autoTable(
        columns,
        vc.applications,
        {
          didDrawPage: pageContent,
          startY: start_y_pos,
          margin: { top: 80 },
        },
        {
          didParseCell(data) {
            if (data.cell.section != "head") {
            
            }
          },
        }
      );
      pdf.save("Applications.pdf");
    },
    async downloadExcel() {
      var columns = [
          "Sr No",
          "Profile No",
          "Registation Type",
          "Entity Name",         
          "Record Date",
          "Registering Agency",
          "Agency Reg No",
      ];
    
      let app_data = [];

      await this.applications.forEach((element) => {
        let obj = {
          "Sr No":"",
          "Profile No": 0,
          "Registation Type":"",
          "Entity Name": "",          
          "Record Date": "",
          "Registering Agency": "",
          "Agency Reg No":null,
        };
        obj["Sr No"] = element.sr_no;

        //obj["Registation No"] = element.RegistrationNumberr;

        obj["Profile No"] = element.RegistrationNumber;

        obj["Registation Type"] = element.RegistrationType;
        obj["Entity Name"] = this.titleCase(element.EntityName);
        obj["Record Date"] = element.RecordDate;
        obj["Registering Agency"] = element.RegisteringAgencny;
        obj["Agency Reg No"] = element.AgencyRegistrationNumber;
        

        app_data.push(obj);
      });

      var vc = this;
      var wb = XLSX.utils.book_new(); 
      var ws = XLSX.utils.json_to_sheet(app_data, { header: columns });
      ws["!cols"] = [
        { wpx: 40 },
        { wpx: 80 },
        { wpx: 120 },
        { wpx: 220 },
        { wpx: 105 },
        { wpx: 220 },
        { wpx: 90 },
        { wpx: 70 }, 
        { wpx: 70 },
      ];
      XLSX.utils.book_append_sheet(wb, ws, "Applications");
      XLSX.writeFile(wb, "Application.xlsx");
    },
    titleCase(str) {
      if ((str != null) & (str != undefined)) {
        return str
          .trim()
          .toLowerCase()
          .split(" ")
          .map(function (word) {
            if (word) {
              return word.replace(word[0], word[0].toUpperCase());
            } else {
              return "";
            }
          })
          .join(" ");
      }
    },
    gender_h(gender) {
      if (gender) {
        return "Male";
      } else {
        return "Female";
      }
    },
    service_man(service) {
      if (service) {
        return "Yes";
      } else {
        return "No";
      }
    },
  },
  created() {
    this.SearchResult();
  },
  mounted() {
    this.form.BoardID = this.userBoard;
    let frm = this.$store.getters.getAppSearchFormPvt;

    if (frm != {} && frm.ApplicationStatus != undefined) {
      this.form = frm;
      this.getApplications();
    }
  },
};
</script>

<style></style>
